<template>
  <div class="bg-glass-effect-lighter" style="height: 100vh;">
    <iframe v-if="!isLoading && encryptedUserId" :src="`${getUrlNotificationPreferences}`" height="100%" width="100%" scrolling="auto" frameborder="0"/>
  </div>
</template>

<script>
import CryptographyService from "../../services/security/Cryptography";
import { mapMutations } from "vuex";

export default {
  name: 'Login',
  data: () => ({
    encryptedUserId: null,
    isLoading: false,
    userId: 0,
    userName: null
  }),
  computed: {
    getUrlNotificationPreferences() {
      return `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_NOTIFICATION_PREFERENCES_PATH}?token=${this.getEncryptedUserIdBase64}.${this.getUserNameBase64}.${this.getAppCodeBase64}`;
    },
    getAppCodeBase64() {
      return btoa(`${process.env.VUE_APP_ACCOUNT_APP_CODE}`);
    },
    getEncryptedUserIdBase64(){
      return btoa(`${this.encryptedUserId}`);
    },
    getUserNameBase64() {
      return btoa(`${this.userName}`);
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getCurrentSessionData(){
      this.userId =  this.$auth.getSession().user.id;
      this.userName = this.$auth.getSession().user.name;
    },
    async encryptUserId(){
      const cryptography = {
        value: this.userId
      }

      await this._cryptographyService.EncryptData(cryptography)
          .then(response => {
            if(response.data) {
              this.encryptedUserId = response.data
            }
          })
          .catch(() => {
            this.showmsg({text: "Não foi possível criptografar a informação.", type: "error"});
          })
    }
  },
  beforeCreate() {
    this._cryptographyService = new CryptographyService();
  },
  async created() {
    this.isLoading = true;
    this.loading(this.isLoading);
    this.getCurrentSessionData();
    await this.encryptUserId();
    setTimeout(() => {
      this.isLoading = false;
      this.loading(this.isLoading);
    }, 3000)
  }
};
</script>

<style scoped>
iframe{
  overflow: hidden !important;
  border: none;
}
</style>
