/* eslint-disable */
import HttpService from '../HttpService';
export default class CryptographyService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security/cryptography`);
  }

  async EncryptData(data){
    return await this._httpService.post('/encrypt', data)
  }

}
